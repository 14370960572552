<template>
  <v-card
    @mouseenter="hover = !hover"
    @mouseleave="hover = !hover"
    rounded="lg"
    class="collection"
    :elevation="hover ? 24 : 6"
    :to="`favorites/collection/${collection.id}`"
  >
    <v-row no-gutters v-if="hasImages">
      <v-col v-for="(image, i) in images" :key="i" cols="6">
        <v-img
          :src="itemPic(image)"
          :aspect-ratio="1"
          :class="`grey lighten-${i + (1 % 3)} border-${i + '-' + $i18n.locale}`"
        >
          <v-row
            v-if="i == 3 && count > 4"
            class="fill-height ma-0 lastImage"
            align="center"
            justify="center"
          >
            <h1>+{{ count - 4 }}</h1>
          </v-row>
        </v-img>
      </v-col>
    </v-row>
    <v-row v-else no-gutters>
      <v-col cols="12">
        <v-img :aspect-ratio="1" class="grey lighten-3">
          <v-row class="fill-height ma-0" align="center" justify="center">
            <h1>No items</h1>
          </v-row>
        </v-img></v-col
      >
    </v-row>

    <v-card-actions class="pa-4">
      <div>
        <div class="headline">
          {{ collection.name }}
        </div>

        <div class="text--secondary">
          <b>
            {{ count }}
            {{ count == 1 ? $t("favoritesPage.item") : $t("favoritesPage.items") }}</b
          >
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    collection: {
      type: Object,
    },
  },
  data() {
    return { hover: false };
  },
  computed: {
    images() {
      return this.collection.meta?.images ?? [];
    },
    hasImages() {
      return this.collection.meta?.images[0];
    },
    count() {
      return this.collection.meta?.favorites_count;
    },
  },
  methods: {
    itemPic(image) {
      console.log(image ?? "null");
      return `https://majaal-system-files.ams3.cdn.digitaloceanspaces.com/${
        image?.url ?? "null"
      }`;
    },
  },
};
</script>

<style>
.border-0-ar {
  border-top-right-radius: 3%;
}
.border-1-ar {
  border-top-left-radius: 3%;
}

.border-0-en {
  border-top-left-radius: 3%;
}
.border-1-en {
  border-top-right-radius: 3%;
}

.collection {
  box-shadow: 0 0 3pt 2pt #000000;
  border-radius: 3%;
  cursor: pointer;
}
.collection:hover {
  box-shadow: 0 0 3pt 2pt #000000;
  border-radius: 3%;
  cursor: pointer;
}
.lastImage {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 2rem;
}
</style>
